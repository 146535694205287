<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4 d-md-none">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">仪表盘</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <!--
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
        -->
      </div>
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card title="登录记录"
                      type="gradient-red"
                      :sub-title="loginedUser.username"
                      icon="ni ni-single-02">

            <template slot="footer">
              <span class="text-nowrap">上次登录</span>
              <span class="text-success ml-2"> {{loginedUser.created_at}}</span>
            </template>
          </stats-card>
        </div>
        <!-- <div class="col-xl-3 col-md-6">
          <stats-card title="扶贫商品销售额"
                      type="gradient-info"
                      sub-title="49,650"
                      icon="ni ni-chart-bar-32">

            <template slot="footer">
              <span class="text-nowrap">全类品总额</span>
              <span class="text-success ml-2"> 989,000</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card title="填报次数"
                      type="gradient-green"
                      sub-title="01"
                      icon="ni ni-archive-2">

            <template slot="footer">
              <span class="text-nowrap">最后填报</span>
              <span class="text-success ml-2"> 2020-11-10</span>
            </template>
          </stats-card>
        </div> -->
        <div class="col-xl-3 col-md-6">
          <stats-card title="系统版本"
                      type="gradient-orange"
                      sub-title="1.1.0"
                      icon="ni ni-settings-gear-65">

            <template slot="footer">
              <span class="text-nowrap">客户端版本</span>
              <span class="text-success ml-2"> {{ clientVersion }}</span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="row justify-content-center">
        <h1 class="my-9 py-8 d-none d-md-flex text-muted font-weight-light ls-3">广州国企消费帮扶数据统计中心</h1>
    </div>

  </div>
</template>
<script>
  // Components
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import config from '../../../package.json'

  export default {
    components: {
      RouteBreadCrumb,
      StatsCard
    },
    data() {
      return {}
    },
    computed: {
      clientVersion () {
        return config.version
      },
      loginedUser() {
        return this.$store.state.user.user || {}
      },
      isSuper() {
        return this.$store.state.user.user && this.$store.state.user.user.isSuper
      }
    },
    methods: {
      
    }
  };
</script>
<style></style>
